import axios from "axios";
var backend_host= `${process.env.VUE_APP_SERVER_HOST}`
var backend_port= process.env.VUE_APP_SERVER_PORT
const apiClient=axios.create({
    baseURL:`http${backend_port == 443 ? "s" : ""}://${backend_host}:${backend_port}`,
    withCredentials:false,
    headers:{
        "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            Authorization:`JWT ${localStorage.getItem('token')}`
    }
})

export default apiClient;