
        export function numFormat(key){
            return Number(key).toLocaleString();
        }
        export const perPage=10;
        export function numFormatWithDollar(key){
            return key ? '$' + Number(key).toLocaleString() : '-';
        }
        export function timeDiff(date1, date2){
            if (!(date1 instanceof Date && date2 instanceof Date))
              throw new RangeError('Invalid date arguments');
      
            const diff = Math.abs(date2.getTime() - date1.getTime()) / 1000;
            var numyears = Math.floor(diff / 31536000);
            var numdays = Math.floor((diff % 31536000) / 86400);
            var numhours = Math.floor(((diff % 31536000) % 86400) / 3600);
            var numminutes = Math.floor((((diff % 31536000) % 86400) % 3600) / 60);
            var numseconds = Math.floor((((diff % 31536000) % 86400) % 3600) % 60);
            return `${numyears}y - ${numdays}d - ${numhours}:${numminutes}:${numseconds}`;
      
          }

        export function localize(value, str) {
            if (value != 1)
                str += 's';

            return `${value} ${str} ago`
        }
        export function get_human_readable_alive_elapsed_time(alive_time, current_time ) {
            const olderDate = new Date(alive_time);
            const currentDate = new Date(current_time);
       
      
            return timeDiff(currentDate, olderDate);
          }

        export function showToastSuccessByResponse(res,this_obj){
            var msg = res.data?res.data.message:res
            this_obj.$toast.open({
          message: msg,
          type: "success",
          duration: 1000 * 5,
          dismissible: true
        })
        }
        export function showProgress(){
            document.getElementById("load").style.display='block'
            
        }
        export function hideProgress(){
          document.getElementById("load").style.display='none'
          

        }
        export function get_current_time() {
            var date_ob = new Date();
            var year = date_ob.getFullYear();
            var month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
            var day = ("0" + date_ob.getDate()).slice(-2);
            var hour = date_ob.getHours();
            var minute = date_ob.getMinutes();
            var second = date_ob.getSeconds();
            return `${year}/${month}/${day}  ${hour}:${minute}:${second}`;
          }

        export function showToastErrorByResponse(res,this_obj){
            var msg = res.response ? res.response.data : res.message?res.message:res
            this_obj.$toast.open({
          message: msg,
          type: "error",
          duration: 1000 * 5,
          dismissible: true
        })
        
        
        }
        export function redirectToLoginPageIfNotAuthenticated(res,route_obj){
          if (res.response) {
          
          
            if (res.response.status==441) {
              console.log("res.response.status");
            console.log(res.response.status);
              route_obj.push({path:'/login'})
            }  
          }
        }

        export function showConfirmationDialog(msg,after_confirm,this_obj){
            this_obj.$confirm(
                {
                  message: `Are you sure To ${msg}?`,
                  button: {
                    no: 'No',
                    yes: 'Yes'
                  },
                  /**
                  * Callback Function
                  * @param {Boolean} confirm
                  */
                  callback: confirm => {
                    if (confirm) {
                      after_confirm()
                    }
                  }
                }
              )
        }
