<template>
  <div class="hello">
    <div class="border-bottom border-bottom-2 mb-3">
    <b-button v-b-modal.modal-user-store class="bg-primary mb-3" 
                title="Add Panel or Agent User ">
                <i class="fa fa-user-plus text-white"></i>
              </b-button>

              <b-modal id="modal-user-store" title="Add Panel or Agent User" ok-variant="success" ok-only ok-title="Store"
                @ok="pnl_user_store">
                <div class="my-4">
                  <div class="mt-3">
                    <b-form-group label="Username" description="Username Of New New User">
                      <b-form-input v-model="panel_store_name" placeholder="Username">

                      </b-form-input>
                    </b-form-group>
                    <b-form-group label="Password" description="Password Of New User">
                      <b-form-input type="password" v-model="panel_store_password" placeholder="Password">

                      </b-form-input>
                    </b-form-group>
                    <b-form-group label="Password Confirmation" description="Confirm Password Of New User">
                      <b-form-input v-model="panel_store_password_confirmation" placeholder="Password Confirmation"
                        type="password">

                      </b-form-input>
                    </b-form-group>
                    <b-form-group label="Type" description="Type Of New User">
                      <b-form-radio type="radio" v-model="panel_store_type" value="0">
                        Agent
                      </b-form-radio>
                      <b-form-radio type="radio" v-model="panel_store_type" value="1">
                        Panel
                      </b-form-radio>
                    </b-form-group>



                  </div>
                </div>
              </b-modal>
              </div>
    <table class="table table-bordered table-light table-striped ">
      <thead>
        <tr>
          <th>#</th>

          <th>Name</th>
          <th>Type</th>
          <th>Agents</th>
          <th>Actions</th>
          <!-- <th>Logged In</th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in users" v-bind:key="user.name">
          <td>{{ index + 1 }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.type == 0 ? "Agent" : "Panel" }}</td>
          <td>agents</td>

          <td>
            <button class="btn btn-danger" v-on:click="deleteUser(user.id)"><i class="fa fa-trash"></i></button>
            <b-button v-b-modal="'modal-user_edit' + user.id" class="bg-primary ms-3" title="Edit User ">
              <i class="fa fa-user-edit text-white"></i>

            </b-button>

            <b-modal :id="`modal-user_edit${user.id}`" title="Edit User" ok-variant="success" ok-only ok-title="Edit"
              @ok="pnl_user_edit(user.id)">
              <div class="my-4">
                <div class="mt-3">
                  <b-form-group label="Password" description="Enter Your New Password">
                    <b-form-input v-model="panel_edit_password" placeholder="Password" type="password">

                    </b-form-input>
                  </b-form-group>
                  <b-form-group label="Password Confirmation" description="Enter Your New Password Again"
                    type="password">
                    <b-form-input v-model="panel_edit_password_confirm" placeholder="Password Confirmation"
                      type="password">

                    </b-form-input>
                  </b-form-group>


                </div>
              </div>
            </b-modal>
          </td>
        </tr>

      </tbody>

    </table>
    <b-row align-h="center" align-content="center">
      <b-col cols="auto">
        <b-pagination v-model="currentPage" :total-rows="total_users_number" :per-page="perPage"
          class="text-center m-auto"></b-pagination>
      </b-col>
    </b-row>


  </div>
</template>

<script>
import { hideProgress, perPage, showConfirmationDialog, showProgress, showToastErrorByResponse, showToastSuccessByResponse } from '@/helpers/helpers';
import apiClient from '@/helpers/apiClient';

export default {
  name: 'UsersComponent',
  props: {
    msg: String
  },
  data() {
    return {
      backend_host: `${process.env.VUE_APP_SERVER_HOST}`,
      backend_port: process.env.VUE_APP_SERVER_PORT,
      users: "[]",
      perPage: perPage,
      panel_store_name:"",
      panel_store_password:"",
      panel_store_password_confirmation:"",
      panel_store_type:0,
      total_users_number: 0,
      currentPage: 1,
      addingUser: false,
      name: "",
      panel_edit_password: "",
      panel_edit_password_confirm: "",
      password: "",
      srv_current_time: "",
      running: false,
      logged_in: false,
    }
  },
  watch: {
      currentPage() {
        
        this.get_usernames_from_backend()
      }
    },
  methods: {
    deleteUser(user_id) {

      showConfirmationDialog('Delete this User', () => {
        apiClient.post(
          `/api/pnl_user/delete?uid=${user_id}`).then((res) => {
            showToastSuccessByResponse(res, this)

            this.users = this.users.filter((user) => { return user.id != user_id })
          }).catch((res) => showToastErrorByResponse(res, this))
      }, this)






    },
   
    pnl_user_edit(user_id) {
      
      var data_to_store = {
        password: this.panel_edit_password,
        password_confirmation: this.panel_edit_password_confirm,
        id: user_id
      };
      showProgress()
      apiClient
        .post("/api/pnl_user/edit", data_to_store)
        .then((res) => {
          hideProgress()
          showToastSuccessByResponse(res, this)
        })
        .catch((res) => {
          showToastErrorByResponse(res, this)
        });
    },
    pnl_user_store() {

      
        var data_to_store = {
          name: this.panel_store_name,
          password: this.panel_store_password,
          password_confirmation: this.panel_store_password_confirmation,
          type: this.panel_store_type,
        };
        showProgress()
      apiClient
        .post("/api/pnl_user/store", data_to_store)
        .then((res) => {
          hideProgress()
          showToastSuccessByResponse(res,this)
          this.users.push({id:data_to_store.new_id,name:data_to_store.name,type:data_to_store.type})
          this.panel_user_store_result = res.data.result;
        })
        .catch((res) => {
          showToastErrorByResponse(res,this)
        });
    },



    get_usernames_from_backend() {
      showProgress()
      apiClient.get(`/api/pnl_user/index?page=${this.currentPage}`)
      .then((result) => {
          hideProgress()
          this.users = result.data.users != undefined ? result.data.users : [];
          this.total_users_number = result.data.total_users_number
          this.srv_current_time = result.data.current_time;
        }).catch(() => { return; });

    }
  },

  mounted() {
    this.get_usernames_from_backend();
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>