<template>
  <div>
    <div>
      <FoldersComponent :uid="panel_user_id" :folderClicked="get_agent_by_folder"
        :agentRemoved="removeAgentFromFolder" />
    </div>
    <div v-if="agents.length > 0" class="hello mt-3">
      <table class="table table-bordered table-striped table-secondary ">
        <thead>
          <tr>
            <th>#</th>

            <th>Name [type]</th>
            <th>Description</th>
            <th>Last Alive</th>
            <th>IPs</th>
            <th>Actions</th>


          </tr>
        </thead>
        <tbody>
          <tr v-for="(agent, index) in agents" v-bind:key="agent.name" draggable="true"
            @dragstart="startDrag($event, agent)" @dragenter.prevent @dragover.prevent>
            <td>{{ index + 1 }}</td>
            <td>{{ agent.name }} <span v-if="agent.agent_type == 0" title="HTTP"><i
                  class="fa fa-globe text-primary"></i></span> <span title="DNS" v-else><i
                  class="fa-brands fa-cloudflare text-danger"></i></span></td>
            <td>
              <!-- do not insert () on v-click-outside action -->
              <div v-if="description_editing_id == agent.id">
              <input 
              
                v-click-outside="update_description" type="text" name="description"
                :value="agent.description" id="" class="text-dark w-50" :ref="`description-${agent.id}`" />
              </div>
              <div v-else v-on:dblclick="enable_description_editing(agent.id)">
                <span v-if="agent.description">{{ agent.description }}</span> <span v-else>No Description</span>
              </div>
            </td>
            <td>{{ get_human_readable_alive_elapsed_time(agent.alive_time) }}</td>
            <td>{{ agent.ips }}</td>
            
            <td>
              <b-button v-b-modal="'modal-user_assign' + agent.id" class="bg-primary me-2" v-if="panel_user_role == 1"
                title="Add Agent to Panel Users ">
                <i class="fa fa-user-plus text-white"></i>

              </b-button>

              <b-modal :id="`modal-user_assign${agent.id}`" title="Add Panel or Agent User">
                <div class="my-4">
                  <div class="mt-3" style="overflow-y: scroll;">
                    <div class="mt-3" v-for="panel_user in panel_users" :key="panel_user.id">
                      <label class="me-3" for="name">{{ panel_user.name }}</label>
                      <input type="checkbox" :checked="checkUserAssignedToAgent(panel_user.id, agent)" class="form-input"
                        @change="assign_agent_to_paneluser(agent.id, panel_user.id)" name="name">
                    </div>


                  </div>
                </div>
              </b-modal>
              <a :href="'/commands/' + `${agent.id}`" class="btn btn-light me-2"><i
                  class="fa fa-terminal text-dark"></i></a>
              <!-- <a :href="'/downloads/' + `${ agent.id}`" class="btn btn-dark me-2"><i
                    class="fa fa-download text-success"></i></a>
                    <a :href="'/uploads/' + `${ agent.id}`" class="btn btn-info me-2"><i
                      class="fa fa-upload text-primary"></i></a> -->
              <button class="btn btn-danger" v-on:click="deleteAgent(agent.id)"><i class="fa fa-trash"></i></button>
            </td>

          </tr>

        </tbody>

      </table>

      <b-row align-h="center" align-content="center" v-if="!search_word">
          <b-col cols="auto">
            <b-pagination v-model="currentPage" :total-rows="total_agents_number" :per-page="perPage"
              class="text-center m-auto"></b-pagination>
          </b-col>
        </b-row>


    </div>
    <div class="alert alert-danger mt-5" v-else>
      {{ no_item_message }}
    </div>

  </div>
</template>

<script>
import apiClient from '@/helpers/apiClient';
import FoldersComponent from './Tools/FoldersComponent.vue';
import { hideProgress, perPage, showConfirmationDialog, showProgress, showToastErrorByResponse, showToastSuccessByResponse } from '@/helpers/helpers';

export default {
  name: 'AgentsComponent',
  props: {
    msg: String
  },
  components: { "FoldersComponent": FoldersComponent },
  data() {
    return {
      backend_host: `${process.env.VUE_APP_SERVER_HOST}`,
      backend_port: process.env.VUE_APP_SERVER_PORT,
      agents: [],
      currentPage: 1,
      perPage:perPage,
      opened_folder_id:"/",
      total_agents_number:0,
      addingUser: false,
      http_user_store_name: "",
      http_user_store_password: "",
      panel_user_role: localStorage.getItem("panel_user_role"),
      panel_users: [],
      panel_user_id: localStorage.getItem("panel_user_id"),
      srv_current_time: "",
      running: false,
      logged_in: false,
      no_item_message: "No Agent Available",
      new_agent_description:"",
      description_editing_id:"",
      tasksList: [
        {
          "name": "task 1",
          "tasks": [
            {
              "name": "task 2",
              "tasks": [
                {
                  "name": "task 4",
                  "tasks": []
                }
              ]
            }
          ]
        },
        {
          "name": "task 3",
          "tasks": []
        },
        {
          "name": "task 5",
          "tasks": []
        }
      ]
    }
  },
  watch: {
    currentPage() {
      if (this.opened_folder_id=="/") {
        this.get_agents_from_backend()

      }
      else{
        apiClient.get(`/api/folder/agents/index?uid=${this.panel_user_id}&page=${this.currentPage}&fid=${this.opened_folder_id}`).then((res) => {
        this.agents = res.data.agents
        this.total_agents_number=res.data.total_agents_number

        this.no_item_message = "No agent is in this Folder"

      }).catch((res) => {
        showToastErrorByResponse(res, this)
        
      })
      }
    }
  },
  methods: {
    startDrag(event, item) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('draggedItemId', item.id ? item.id : item._id)


    },
    get_agent_by_folder(folder_id) {
      this.opened_folder_id=folder_id
      if (folder_id == "/") {
        this.get_agents_from_backend()
        return
      }
      apiClient.get(`/api/folder/agents/index?uid=${this.panel_user_id}&page=${this.currentPage}&fid=${folder_id}`).then((res) => {
        this.currentPage=1
        this.agents = res.data.agents
        this.total_agents_number=res.data.total_agents_number

        this.no_item_message = "No agent is in this Folder"

      }).catch((res) => {
        showToastErrorByResponse(res, this)
        
      })
    },
    onDrop(event, targetObj) {
      var draggedAgentId = event.dataTransfer.getData('draggedItemId')
      // const item = this.agents.find((item)=>item.id==draggedItemId || item._id==draggedItemId)


      var draggedAgentElementIndex = this.agents.indexOf(this.agents.find((el) => el.id == draggedAgentId))
      var droppedAgentElementIndex = this.agents.indexOf(this.agents.find((el) => el.id == targetObj.id))


      var temp_agents = this.agents
      const temp = temp_agents[draggedAgentElementIndex];
      temp_agents[draggedAgentElementIndex] = temp_agents[droppedAgentElementIndex];
      temp_agents[droppedAgentElementIndex] = temp;
      this.agents = []

      this.agents = temp_agents

    },
    deleteAgent(agent_id) {

      showConfirmationDialog('Delete this Agent', () => {
        showProgress()
        apiClient.post(
          `/api/agent/delete/${agent_id}`

        ).then((res) => {
          hideProgress()

          showToastSuccessByResponse(res, this)
          this.agents = this.agents.filter((agent) => { return agent.id != agent_id })
        }).catch((res) => {

          showToastErrorByResponse(res, this)
        })
      }, this)







      // location.reload()

    },
    timeDiff(date1, date2) {
      if (!(date1 instanceof Date && date2 instanceof Date))
        throw new RangeError('Invalid date arguments');

      // const timeIntervals = [31536000, 2628000, 604800, 86400, 3600, 60, 1];
      // const intervalNames = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];

      const diff = Math.abs(date2.getTime() - date1.getTime()) / 1000;
      var numyears = Math.floor(diff / 31536000);
      var numdays = Math.floor((diff % 31536000) / 86400);
      var numhours = Math.floor(((diff % 31536000) % 86400) / 3600);
      var numminutes = Math.floor((((diff % 31536000) % 86400) % 3600) / 60);
      var numseconds = Math.floor((((diff % 31536000) % 86400) % 3600) % 60);
      return `${numyears}y - ${numdays}d - ${numhours}:${numminutes}:${numseconds}`;

    },

    localize(value) {
      if (value != 1) {
        return 's'

      }
    },
    get_human_readable_alive_elapsed_time(alive_time, current_time = this.srv_current_time) {
      const olderDate = new Date(alive_time);

      const currentDate = new Date(current_time);
      return this.timeDiff(currentDate, olderDate);
    },
    get_current_time() {
      var date_ob = new Date();
        var year = date_ob.getFullYear()
        var month = ("0" + (date_ob.getMonth() + 1)).slice(-2)
        var day = ("0" + date_ob.getDate()).slice(-2)
        var hour = date_ob.getHours()
        var minute = date_ob.getMinutes()
        var second = date_ob.getSeconds()

        return `${year}/${month}/${day}  ${hour}:${minute}:${second}`
     
    },


    assign_agent_to_paneluser(agent_id, panel_user_id) {
      var data_to_send = { agent_id: agent_id, user_id: panel_user_id }
      showProgress()
      apiClient.post(`/api/pnl_user/agent/assign`, data_to_send).then((res) => {
        //TODO:remain check/uncheck after assign/unassign
        hideProgress()
        this.agents.map((agent)=>{
          if (agent.id==agent_id) {
            return {...agent,users:agent.users.map((user)=>{
              user.id==panel_user_id
            })}
          }
        })
        showToastSuccessByResponse(res, this)
      }).catch((res) => showToastErrorByResponse(res, this))
    },
    get_agents_from_backend() {
      showProgress()
      apiClient.get(`/api/agent/index?page=${this.currentPage}`)
        .then((result) => {
          hideProgress()
          this.total_agents_number=result.data.total_agents_number
          this.agents = result.data.agents != undefined ? result.data.agents : [];
          this.srv_current_time = result.data.current_time;
          this.panel_users = result.data.panel_users

        }).catch((res) => {
          showToastErrorByResponse(res,this)

        });

    },
    removeAgentFromFolder(agent_id) {

      this.agents = this.agents.filter((agent) => agent.id != agent_id)
    },
    checkUserAssignedToAgent(user_id, agent_obj) {

      if (agent_obj.users) {
        var filtered_agent_arr = agent_obj.users.filter((user) => { return user.id == user_id })
        if (filtered_agent_arr.length > 0) {
          return true
        }
      }
      return false
    },
    enable_description_editing(id){
      this.description_editing_id = id;
      
    },
    update_description() {
      var ref_id = "description-" + this.description_editing_id

        var inputValue = this.$refs[ref_id][0].value
      var data_to_update = { description: inputValue }
      showProgress()
      apiClient.post(`/api/agent/update/${this.description_editing_id}`,data_to_update).then((res)=>{hideProgress();showToastSuccessByResponse(res,this)}).catch((res)=>{
        showToastErrorByResponse(res,this)
      })
      this.agents=this.agents.map((agent) => {
          if (agent.id == this.description_editing_id) {
            return {...agent ,description : inputValue}
          }
          else{
            return agent
          }
        })
      this.new_agent_description=""
      this.description_editing_id = ""
    }
  },
  directives: {
        'click-outside': {
            bind(el, binding) {
                el.clickOutsideEvent = function (event) {
                    if (!(el == event.target || el.contains(event.target))) {
                        binding.value(event);
                    }
                };
                document.addEventListener('click', el.clickOutsideEvent);
            },
            unbind(el) {
                document.removeEventListener('click', el.clickOutsideEvent);
            },
        },
    },

  mounted() {
    this.get_agents_from_backend();

  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
