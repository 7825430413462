<template>
  <div class="border-bottom border-bottom-2 p-2">
    <div
      class="overflow-scroll overflow-hidden"
      style="overflow-y: none; height: 50px; white-space: nowrap"
    >
      <div
        class="d-inline-block bg-success text-center rounded-2 me-2"
        style="cursor: pointer"
      >
        <b-button
          v-b-modal.modal-add-useful-cmd
          class="bg-info"
          title="Add Useful command "
        >
          <i class="fa fa-plus"></i>
        </b-button>
      </div>
      
      <div
        class="d-inline-block p-1  bg-success text-white text-center rounded-2 me-2"
        v-bind:key="usefulCmd.id"
        v-for="usefulCmd in usefulCmds"
        :title="usefulCmd.cmd"
      >
        <i
          class="fa fa-times rounded-circle mb-2"
          @click="useful_command_delete(usefulCmd.id)"
        ></i>

        <span
          @click="$props.retypeCommand(usefulCmd.cmd)"
          class="ms-2 me-1 d-inline-block badge"
          style="
            cursor: pointer;
            width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
        >
          {{ usefulCmd.caption }}
        </span>
      </div>

      <b-modal id="modal-add-useful-cmd" title="Add Useful Commnd" ok-variant="success" ok-only ok-title="Store" @ok="new_useful_cmd_store">
        <div class="my-4">
          <div class="mt-3">
            <div>
                <b-form-group label="Caption" description="caption of new usefull command" >
                    <b-form-input v-model="new_useful_cmd_caption" placeholder="Caption" >

                    </b-form-input>
                </b-form-group>
                <b-form-group label="body" description="body of new usefull command" >
                    <b-form-input v-model="new_useful_cmd_body" placeholder="command" trigger="enter">

                    </b-form-input>
                </b-form-group>
                
            </div>

            <!-- <div>
              <button class="btn btn-success" @click="new_useful_cmd_store">
                store
              </button>
            </div> -->
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import apiClient from "@/helpers/apiClient";
import { hideProgress, showConfirmationDialog, showProgress, showToastErrorByResponse, showToastSuccessByResponse } from "@/helpers/helpers";

export default {
  props: { type: String, retypeCommand: Function },
  data() {
    return {
      new_useful_cmd_caption: "",
      new_useful_cmd_body: "",
      usefulCmds: [],
      input_type: this.$parent.type,
    };
  },
  methods: {
    new_useful_cmd_store() {
      var data_to_store = {
        cmd: this.new_useful_cmd_body,
        type: this.$props.type,
        caption: this.new_useful_cmd_caption,
      };
      showProgress()
      apiClient
        .post("/api/usefulcmd/store", data_to_store)
        .then((res) => {
          hideProgress()
         showToastSuccessByResponse(res,this)
          this.usefulCmds.push(data_to_store);
          this.new_useful_cmd = "";
        })
        .catch((res) => {
          showToastErrorByResponse(res,this)
        });
    },
    useful_command_delete(useful_cmd_id) {
      showConfirmationDialog(
        "Delete this useful commands",
        () => {
          apiClient
            .post(`/api/usefulcmd/delete/${useful_cmd_id}`)
            .then((res) => {
              showToastSuccessByResponse(res,this)
              this.usefulCmds = this.usefulCmds.filter(
                (us_cmd) => us_cmd.id != useful_cmd_id
              );
              this.new_useful_cmd = "";
            })
            .catch((res) => {
              showToastErrorByResponse(res,this)
            });
        },

        this
      );
    },
    useful_cmd_index() {
      apiClient
        .get(`/api/usefulcmd/index?type=${this.$props.type}`)
        .then((res) => {
          this.usefulCmds = res.data.useful_cmds;
        })
        .catch((res) => {
         showToastErrorByResponse(res,this)
        });
    },
  },
  watch: {
    type: function (newVal) {
      // watch it
      this.useful_cmd_index();
      this.input_type = newVal;
    },
  },
  mounted() {
    this.useful_cmd_index();
  },
};
</script>
