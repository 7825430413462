<template>
    <b-dropdown id="dropdown-1" html="<i class='fa fa-user'></i>">
        <b-dropdown-header id="dropdown-header-label">
            <h5>
                User Dashboard
            </h5>
            <h6 class="text-primary d-inline">
                {{ $props.panel_user_name }}

            </h6>
            <span :class="`badge bg-${$props.panel_user_role == 1 ? 'success' : 'info'} text-white`">{{
                $props.panel_user_role == 1 ? "Admin" : "Panel" }}</span>

        </b-dropdown-header>
        <b-list-group>

            <b-list-group-item>
                <b-button variant="light" class="w-100" @click="logout">
                    <div>
                        <p class="">
                            <i class="fa fa-sign-out text-danger"></i> <span>Logout</span>
                        </p>
                    </div>
                </b-button>
            </b-list-group-item>
            <b-list-group-item>
                <b-button v-b-modal.modal-user-edit title="Edit User" class="w-100" variant="light">
                    <i class="fa fa-user-edit me-2 text-info"></i> <span>Edit User</span>
                </b-button>

                <b-modal id="modal-user-edit" title="Edit User" ok-variant="success" ok-only ok-title="Edit"
                    @ok="pnl_user_edit">
                    <div class="my-4">
                        <div class="mt-3">


                            <b-form-group label="Password" description="Enter Your New Password">
                                <b-form-input v-model="panel_edit_password" placeholder="Password" type="password">

                                </b-form-input>
                            </b-form-group>
                            <b-form-group label="Password Confirmation" description="Enter Your New Password Again"
                                type="password">
                                <b-form-input v-model="panel_edit_password_confirm" placeholder="Password Confirmation">

                                </b-form-input>
                            </b-form-group>



                        </div>
                    </div>
                </b-modal>
            </b-list-group-item>
        </b-list-group>
    </b-dropdown>
</template>
<script>
import apiClient from '@/helpers/apiClient';
import router from "@/router";
import { hideProgress, showConfirmationDialog, showProgress, showToastErrorByResponse, showToastSuccessByResponse } from '@/helpers/helpers';
export default {

    name: "UserDashboardComponent",
    props: {
        panel_user_role: Number,
        panel_user_name: String,
        panel_user_id:String

    },
    data() {
        return {
                panel_edit_password: "",
                panel_edit_password_confirm: "",
            };
        

    },
    methods: {
        logout() {
            showConfirmationDialog(
                "Do you want to Logout?",
                () => {
                    apiClient.get("/api/pnl_logout").then(() => {
                        localStorage.setItem("token", "");
                        router.push("/login");
                    });
                },
                this
            );
        },
        pnl_user_edit() {
            var data_to_store = {
                password: this.panel_edit_password,
                password_confirmation: this.panel_edit_password_confirm,
                id: this.$props.panel_user_id
            };
            showProgress()
            apiClient
                .post("/api/pnl_user/edit", data_to_store)
                .then((res) => {
                    hideProgress()
                    showToastSuccessByResponse(res, this)
                })
                .catch((res) => {
                    showToastErrorByResponse(res, this)
                });
        },
    }
}
</script>
