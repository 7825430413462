import VueRouter from "vue-router";
// import HelloWorld from "@/components/HelloWorld.vue";
// import UsersComponent from "@/components/Users.vue";
import AgentsComponent from "@/components/Agents.vue";
import CommandsComponent from "@/components/Commands.vue";
import Login from "@/components/Auth/Login.vue";
import Users from "@/components/Admin/Users.vue";
import Tokens from "@/components/Admin/Tokens.vue";

const routes = [
    {
        path: "/",
        
        name: "Root",
        component: AgentsComponent,
        meta:{
            requiresAuth:true
        }
    },
    {
        path: "/agents",
        name: "Agents",
        component: AgentsComponent,
        meta:{
            requiresAuth:true
        }
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
        meta:{
            requiresAuth:true
        }
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
        meta:{
            requiresAuth:false
        }
    },
    {
        path: "/commands",
        name: "Commands",
        component: CommandsComponent,
        meta:{
            requiresAuth:true
        }

    },
    {
        path: "/commands/:uid",
        name: "Commands_by_id",
        component: CommandsComponent,
        meta:{
            requiresAuth:true
        }
        
    },

  
    {
        path: "/server_tokens",
        name: "Server Tokens",
        component: Tokens,
        meta:{
            requiresAuth:true
        }

    },
    
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes:routes,
});
router.beforeEach((to,from,next)=>{
    const token=localStorage.getItem('token');
    
    if (token) {
        if (to.meta.requiresAuth) {
            next()
        }
        else{
            next('/')
        }
    }


    else{
        next()
    }
})
export default router;