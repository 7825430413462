<template>
  <div class="hello">
    <div class="border-bottom border-bottom-2 mb-3">

      <b-button v-b-modal.add-server-token class="bg-primary mb-3"
                title="Add Server Token ">
                <i class="fa fa-key text-white"></i>
                <i class="fa fa-plus ms-1 text-white"></i>
              </b-button>

              <b-modal id="add-server-token" title="Add Server Token" ok-variant="success" ok-only ok-title="Store"
                @ok="token_store">

                <div class="my-4">
                  <div class="mt-3">

                    <b-form-group label="IP" description="IP Of New Token">
                      <b-form-input v-model="token_store_ip" placeholder="IP">

                      </b-form-input>
                    </b-form-group>

                  </div>
                </div>
              </b-modal>
    </div>
    <table class="table table-bordered table-light table-striped ">
      <thead>
        <tr>
          <th>#</th>

          <th>IP</th>
          <th>Token</th>
          <th>Encryption Key</th>
          <th>Actions</th>
          <!-- <th>Logged In</th> -->
        </tr>
      </thead>
      <tbody>
        <tr v-for="(token, index) in tokens" v-bind:key="token.name">
          <td>{{ index + 1 }}</td>
          <td>
            <input v-if="token_ip_is_editing_id == token.id" :ref="`ip_input-${token.id}`" v-click-outside="token_ip_edit"
              type="text" name="token_ip" :value="token.ip" id="" class="text-dark w-50" />
            <span v-else :ref="`ip_span-${token.id}`" v-on:dblclick="ip_edit_start(token.id)" class="text-dark me-2"
              style="cursor: pointer;">
              {{ token.ip }}</span>

          </td>
          <td>
            <p style="width:150px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display:inline-block;"
              :ref="`token-p${token.id}`">{{ token.token }}</p><button class="btn btn-secondary"
              @click="copy(token.id)"><i class="fa fa-copy"></i></button>
              <b-button v-b-modal="'modal-token_edit' + token.id" class="bg-primary ms-3" title="Refresh Token "
              @click="token_refresh(token.id,true)">
              <i class="fa fa-refresh text-white"></i>

            </b-button>
          </td>
          <td>
            <p style="width:150px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display:inline-block;"
              :ref="`enckey-p${token.id}`">{{ token.enc_key }}</p><button class="btn btn-secondary"
              @click="copy_encKey(token.id)"><i class="fa fa-copy"></i></button>
              <b-button v-b-modal="'modal-token_edit' + token.id" class="bg-primary ms-3" title="Refresh Token "
              @click="token_refresh(token.id,false)">
              <i class="fa fa-refresh text-white"></i>

            </b-button>
          </td>
          


          <td>
            <button class="btn btn-danger" v-on:click="deleteToken(token.id)"><i class="fa fa-trash"></i></button>
            


          </td>
        </tr>

      </tbody>

    </table>
    <b-row align-h="center" align-content="center" v-if="!search_word">
          <b-col cols="auto">
            <b-pagination v-model="currentPage" :total-rows="total_tokens_number" :per-page="perPage"
              class="text-center m-auto"></b-pagination>
          </b-col>
        </b-row>


  </div>
</template>

<script>
import { hideProgress, perPage, showConfirmationDialog, showProgress, showToastErrorByResponse, showToastSuccessByResponse } from '@/helpers/helpers';
import apiClient from '@/helpers/apiClient';

export default {
  name: 'TokensComponent',
  props: {
    msg: String
  },
  data() {
    return {
      backend_host: `${process.env.VUE_APP_SERVER_HOST}`,
      backend_port: process.env.VUE_APP_SERVER_PORT,
      tokens: [],
      token_store_ip: "",
      currentPage:0,
      perPage:perPage,
      token_ip_is_editing_id: 0,
      total_tokens_number:0
    }
  },
  watch: {
    currentPage() {
      this.get_tokens_from_backend()
    }
  },
  methods: {
    deleteToken(token_id) {

      showConfirmationDialog('Delete this Token', () => {
        showProgress()
        apiClient.post(
          `/api/server_token/delete/${token_id}`).then((res) => {
            showToastSuccessByResponse(res, this)
            hideProgress()
            this.tokens = this.tokens.filter((token) => { return token.id != token_id })
          }).catch((res) => showToastErrorByResponse(res, this))
      }, this)






    },
    token_store() {

var data_to_store = {
  ip: this.token_store_ip,
};
showProgress()
apiClient
  .post("/api/server_token/store", data_to_store)
  .then((res) => {
    showToastSuccessByResponse(res, this)
    hideProgress()
    var new_token_record=res.data
    delete new_token_record["message"]
    new_token_record["ip"]=this.token_store_ip
    this.tokens.push(new_token_record)
  })
  .catch((res) => {
    showToastErrorByResponse(res, this)
  });
},
    token_refresh(token_id,is_token) {
      var data_to_store={}
      if (is_token) {
         data_to_store = {
        token_refresh: true,
        id: token_id
      };
      }
      else{
         data_to_store = {
        enckey_refresh: true,
        id: token_id
      };
      }
      
      showProgress()
      apiClient
        .post("/api/server_token/edit", data_to_store)
        .then((res) => {
          hideProgress()
          this.tokens=this.tokens.map((token)=>{
            if(token.id==token_id){
              return is_token? {...token,token:res.data.token}:{...token,enc_key:res.data.enc_key}
            }else
            {
              return token
            }})
          showToastSuccessByResponse(res, this)
        })
        .catch((res) => {
          showToastErrorByResponse(res, this)
        });
    },
    token_ip_edit() {

        var ref_id = "ip_input-" + this.token_ip_is_editing_id

        var inputValue = this.$refs[ref_id][0].value

        var data_to_store = {

          ip: inputValue,
          id: this.token_ip_is_editing_id
        };
        showProgress()
        apiClient
          .post("/api/server_token/edit", data_to_store)
          .then((res) => {
            hideProgress()
            showToastSuccessByResponse(res, this)

          })
          .catch((res) => {
            showToastErrorByResponse(res, this)
          });
        this.tokens.map((token) => {
          if (token.id == this.token_ip_is_editing_id) {
            token.ip = inputValue
          }
        })
        this.token_ip_is_editing_id = 0

    },

    ip_edit_start(token_id) {

      this.token_ip_is_editing_id = token_id
    },



    get_tokens_from_backend() {
      showProgress()
      apiClient.get(`/api/server_token/index?page=${this.currentPage}`)
        .then((result) => {
          hideProgress()
          this.tokens = result.data.tokens != undefined ? result.data.tokens : [];
          this.total_tokens_number=result.data.total_tokens_number
          this.srv_current_time = result.data.current_time;
        }).catch(() => { return; });

    },
    copy(index) {
      const selection = document.getSelection();
      selection.removeAllRanges()

      var elem_to_copy_from = this.$refs[`token-p${index}`][0]

      if (document.selection) {
        var range = document.body.createTextRange();
        range.moveToElementText(elem_to_copy_from);
        range.select().createTextRange();
        document.execCommand("copy");
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNode(elem_to_copy_from);
        window.getSelection().addRange(range);
        document.execCommand("copy");
      }
      showToastSuccessByResponse("Token Copied Succesfuly!", this)
    },
    copy_encKey(index) {
      const selection = document.getSelection();
      selection.removeAllRanges()


      var elem_to_copy_from = this.$refs[`enckey-p${index}`][0]

      if (document.selection) {
        var range = document.body.createTextRange();
        range.moveToElementText(elem_to_copy_from);
        range.select().createTextRange();
        document.execCommand("copy");
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNode(elem_to_copy_from);
        window.getSelection().addRange(range);
        document.execCommand("copy");
      }
      showToastSuccessByResponse("Encryption key Copied Succesfuly!", this)
    },
  },
  directives: {
    'click-outside': {
      bind(el, binding) {
        el.clickOutsideEvent = function (event) {
          if (!(el == event.target || el.contains(event.target))) {
            binding.value(event);
          }
        };
        document.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.removeEventListener('click', el.clickOutsideEvent);
      },
    },
  },

  mounted() {
    this.get_tokens_from_backend();
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>