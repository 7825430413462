<template>
    <div class=" header-shadow rounded-3 " >
        <div class="row px-4 py-3">
            
            <div  class="col-3">
                <div class="bg-white rounded-2 text-center">
                    <span class="">
                        <button @click="$props.setInputType(0)"  class="btn text-dark">
                            <i class="fa fa-terminal"></i><i class="fa fa-user ms-1"></i></button>
                    </span>
                    <span class="">
                        <button @click="$props.setInputType(2)"  class="btn text-primary"><i class="fa fa-upload"></i><i
                                class="fa fa-user ms-1"></i></button>
                    </span>
                    <span class="">
                        <button @click="$props.setInputType(1)"  class="btn text-success"><i class="fa fa-download"></i><i
                                class="fa fa-user ms-1"></i></button>
                    </span>
                </div>
                
                
                <div>
                    
                    <SearchComponent :search="$props.search"/>
                </div>
            </div>
            <div class="col-7 text-center">
                <div>
                    <span class="bg-info p-1 rounded-3 badge agentname-header">

                        <span v-if="type == 'Download'" class="text-success">
                            <i class="fa fa-download"></i><i class="fa fa-user ms-1"></i>
                        </span>
                        <span v-if="type == 'Upload'" class="text-primary">
                            <i class="fa fa-upload"></i><i class="fa fa-user ms-1"></i>
                        </span>
                        <span v-if="type == 'Command'" class="text-dark">
                            <i class="fa fa-terminal"></i><i class="fa fa-user ms-1"></i>
                        </span>
                        <span class="font-weight-bolder text-primary">{{
                            agent_name
                        }}</span>
                        <span v-if="agent_type == '0'" >
                            <i class="fa fa-globe text-dark"></i>
                        </span>
                        <span v-else>
                            <i class="fa-brands fa-cloudflare text-danger"></i>
                        </span>
                    </span>

                </div>
                <div>
                    <span v-if="agent_description" class="bg-primary p-1 rounded-3 badge ageantname-header">

                        {{agent_description}}
                    </span>

                </div>
            </div>
            <div class="col-2 text-end">
                <div>
                    <!-- <div>
                        {{ type }}s of User :<span class="font-weight-bolder text-primary">{{ agent_name }}</span>
                    </div> -->


                    <div>
                        <p class="text-warning">
                            {{ alive_time }} <i class="fa fa-clock text-white"></i>
                        </p>
                    </div>
                    <div class="form-group">
                        <input v-if="alive_duration_editing" v-click-outside="aliveDurationUpdate" type="number"
                            name="alive_duration" v-model="alive_duration" id="" class="text-dark w-50" />
                        <span v-on:dblclick="alive_duration_editing = true" class="text-warning me-2" style="cursor: pointer;" v-else>
                            {{ alive_duration }}</span>
                        <label for="alive_duration" class="text-white"><i class="fa fa-refresh"> </i></label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import apiClient from "@/helpers/apiClient";
import SearchComponent from "../Tools/SearchComponent.vue";
import {  hideProgress, showProgress, showToastErrorByResponse, showToastSuccessByResponse } from "@/helpers/helpers";

export default {
    name: "HeaderComponent",
    components:{'SearchComponent':SearchComponent},
    props: {
        type: String,
        alive_time: String,
        agent_name: String,
        agent_description: String,
        agent_id: String,
        agent_type: String,
        alive_duration_init: Number,
        search:Function,
        setInputType:Function
    },
    data() {
        return {
            backend_host: `${process.env.VUE_APP_SERVER_HOST}`,
            backend_port: process.env.VUE_APP_SERVER_PORT,
            alive_duration: this.$props.alive_duration_init,
            alive_duration_editing: false,
        };
    },
    directives: {
        'click-outside': {
            bind(el, binding) {
                el.clickOutsideEvent = function (event) {
                    if (!(el == event.target || el.contains(event.target))) {
                        binding.value(event);
                    }
                };
                document.addEventListener('click', el.clickOutsideEvent);
            },
            unbind(el) {
                document.removeEventListener('click', el.clickOutsideEvent);
            },
        },
    },
    watch: {
        alive_duration_init: function (newVal) {
            // watch it
            this.alive_duration = newVal;
        },
    },
    methods: {
        aliveDurationUpdate() {
            if (parseInt(this.alive_duration) == this.alive_duration) {

                var data_to_store = {
                    alive_duration: this.alive_duration,
                };
                showProgress()
                apiClient
                    .post(
                        `/api/agent/update/${this.agent_id}`,
                        data_to_store
                    )
                    .then((res) => {
                        hideProgress()
                        showToastSuccessByResponse(res,this)
                    })
                    .catch((res) => {
                        showToastErrorByResponse(res,this)
                    });
            }
            this.alive_duration_editing = false;
        },
        
    },

    
};
</script>
