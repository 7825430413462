<template>
    <div class="border-bottom border-bottom-primary border-bottom-2">
        <div class="overflow-scroll overflow-hidden" style="overflow-y:none;height: 60px;white-space: nowrap;">
            <div class="d-inline-block bg-info text-center rounded-2 me-2 " style="cursor:pointer;">
                <b-button v-b-modal.modal-add-folder class="bg-transparent" title="Add New Agent Folder">
                    <i class="fa fa-plus"></i>

                </b-button>

            </div>
            <div class="d-inline-block bg-danger text-center rounded-2 me-2 "
                v-if="openedFolderId!=0"
                @drop="agentRemoveFromFolder($event, openedFolderId)" @dragenter.prevent @dragover.prevent
                style="cursor:pointer;">
                <b-button v-b-modal.modal-add-folder class="bg-transparent" title="Remove Agent From Folder">
                    <i class="fa fa-trash"></i>

                </b-button>

            </div>
            <i class="fa fa-pipe text-light"></i>
            <div :class="`d-inline-block p-1 ${openedFolderId ? 'bg-info' : 'bg-success'} ${openedFolderId ? 'text-dark' : 'text-light'} text-center rounded-2 me-2 `"
                style="cursor:pointer;width:120px;white-space: nowrap;text-overflow: ellipsis;"
                @click="() => { clickOnFolder('/') }">
                <i >
                   / 

                    
                </i>
                
            </div>
            <div :class="`d-inline-block p-1 ${folder.id != openedFolderId ? 'bg-info' : 'bg-success'} ${folder.id != openedFolderId ? 'text-dark' : 'text-light'} text-center  rounded-2 me-2 `"
                @drop="agentAddToFolder($event, folder.id)" @dragenter.prevent @dragover.prevent style="cursor:pointer;"
                v-bind:key="folder.id" v-for="folder in folders" :title="folder.name">
                <i class="fa fa-times rounded-circle" @click="folder_delete(folder.id)"></i>

                <span @click="() => { clickOnFolder(folder.id) }" class="d-inline-block ms-3"
                    style="width:120px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                    <i v-if="folder.id != openedFolderId" class="fa fa-folder"></i>
                    <i v-else class="fa fa-folder-open"></i>

                    {{ folder.name }}
                </span>
            </div>



            <b-modal id="modal-add-folder" title="Add New Agent Folder" ok-variant="success" @ok="new_agent_folder_store" ok-only ok-title="Add" >
                <div class="my-4">
                    <div class="mt-3">
                        <div>
                            <label class="me-3" for="name">Folder Name</label>
                            <input type="text" class="form-input" v-model="new_folder_name"
                                v-on:keyup.enter="new_agent_folder_store()" placeholder="Folder Name" name="name">
                        </div>

                    </div>
                </div>
            </b-modal>
        </div>
    </div>
</template>

<script>
import { hideProgress, showConfirmationDialog, showProgress, showToastErrorByResponse, showToastSuccessByResponse } from '@/helpers/helpers';
import apiClient from '@/helpers/apiClient';

export default {
    props: { uid: String, folderClicked: Function,agentRemoved:Function },
    data() {
        return {
            new_folder_name: "",
            folders: [],
            openedFolderId: 0
        }
    },
    methods: {
        new_agent_folder_store() {
            var new_folder_name_ = this.new_folder_name

            var data_to_store = { name: new_folder_name_, user_id: this.$props.uid }
            showProgress()
            apiClient.post("/api/folder/store", data_to_store).then((res) => {
                showToastSuccessByResponse(res, this)
                hideProgress()
                data_to_store["id"]=res.data.id
                
                this.folders.push(data_to_store)
                this.new_folder_name = ""
            }).catch((res) => {
                showToastErrorByResponse(res, this)
            })
        },
        agentAddToFolder(event, folder_id) {

            var draggedAgentId = event.dataTransfer.getData('draggedItemId')

            var data_to_store = { uid: localStorage.getItem('panel_user_id'), fid: folder_id, agid: draggedAgentId }
            showProgress()
            apiClient.post("/api/folder/agents/store", data_to_store).then((res) => {
                showToastSuccessByResponse(res, this)
                hideProgress()
                if (this.openedFolderId!=0) {

                    this.$props.agentRemoved(draggedAgentId)
                }
            }).catch((res) => {
                showToastErrorByResponse(res, this)
            })







        },
        folder_delete(folder_id) {
            if (folder_id) {
                showConfirmationDialog("Delete this Folder", () => {
                    var data_to_store = { uid: localStorage.getItem('panel_user_id'), fid: folder_id }
                    apiClient.post("/api/folder/delete", data_to_store).then((res) => {
                        showToastSuccessByResponse(res, this)
                        this.folders = this.folders.filter((folder) => folder.id != folder_id)
                    }).catch((res) => {
                        showToastErrorByResponse(res, this)
                    })
                },this)



            }
        },
        clickOnFolder(folder_id) {
            if (folder_id == ('/')) {
                this.openedFolderId = 0
                this.$props.folderClicked('/')
            }
            else {
                this.$props.folderClicked(folder_id)
                this.openedFolderId = folder_id
            }

        },
        agentRemoveFromFolder(event, folder_id) {
            var draggedAgentId = event.dataTransfer.getData('draggedItemId')

            if (folder_id) {
                showConfirmationDialog("Remove the Agent from this Folder",()=>{


                var data_to_store = { uid: localStorage.getItem('panel_user_id'), fid: folder_id, agid: draggedAgentId }
                apiClient.post("/api/folder/agents/delete", data_to_store).then((res) => {
                    showToastSuccessByResponse(res, this)
                    this.$props.agentRemoved(draggedAgentId)

                }).catch((res) => {
                    showToastErrorByResponse(res, this)
                })
                },this)


            }






        },
        folders_index() {
            apiClient.get(`/api/folder/index?uid=${this.$props.uid}`).then((res) => {

                this.folders = res.data.folders
            }).catch((res) => {
                showToastErrorByResponse(res, this)
            })
        }
    },
    mounted() {
        this.folders_index()

    }
}
</script>

<style>
.fa-times:hover {
    color: red;
}
</style>