<template>
  <!-- <div class="header-shadow rounded-3" :style="`background-image: url('${require('../../assets/banner.jpg')}');`"> -->
  <div class="header-shadow rounded-3" >

    <div class="row px-4 py-3">
      <div class="col-3">
        <div class="" id="navbarNav">
          <b-button @click="onSidebarToggle" variant="transparent" > <i class="fa fa-bars text-light"></i> </b-button>
          <b-sidebar v-model="sidebarVisible" class="fixed-sidebar sidebar-400" id="sidebar-variant" no-close-on-route-change no-auto-close no-header
            title="Sidebar" bg-variant="light" text-variant="light" static visible>
            <div class="px-3 py-2 text-center ">


              <img class="w-50 rounded rounded-5" src="../../assets/Logo.png" />
            </div>
            <b-list-group>
              <b-list-group-item v-if="panel_user_role == 1" :class="`${$route.path=='/users'?'selected':''}`">

                <b-button variant="light" class="text-dark w-100 text-start">
                  <router-link to="/users" class="nav-link ">
                    <i class="fa fa-users me-2"></i> <span>  Users</span>
                  </router-link>
                </b-button>
              </b-list-group-item>
              <b-list-group-item v-if="panel_user_role == 1" :class="`${$route.path=='/server_tokens'?'selected':''}`">
                <b-button variant="light" class="w-100 text-start">
                  <router-link to="/server_tokens" class="nav-link text-dark ">
                    <i class="fa fa-key me-2"></i> <span>Server Tokens</span>
                  </router-link>
                </b-button>
              </b-list-group-item>
              <b-list-group-item :class="`${$route.path=='/'?'selected':''}`">
                <b-button variant="light" class="w-100 text-start">
                  <router-link to="/" class="nav-link text-dark ">
                    <i class="fa fa-user-secret me-2"></i> <span>Agents</span>
                  </router-link>
                </b-button>
              </b-list-group-item>
            </b-list-group>
          </b-sidebar>
          <ul class="list-unstyled row">
            <!-- <li v-if="this.$route.path != '/'" class="nav-item col-3">
              <router-link to="/" class="nav-link text-white">
                <i class="fa fa-arrow-left me-3"></i><i class="fa fa-user-secret"></i></router-link>
            </li> -->




            <li v-if="this.$route.path == '/users'" class="col-3">
             
            </li>
            <li v-if="this.$route.path == '/server_tokens'" class="col-3">
            
            </li>
          </ul>
        </div>
      </div>

      <div class="col-6 text-center">
        <div>
          <span class=" p-1 rounded-3 username-header">
            <span class="font-weight-bolder">{{
              panel_user_name
            }}</span>
            <span>
              <i class="fa fa-user text-white ms-3"></i>
            </span>
          </span>
        </div>
      </div>
      <div class="col-3 text-end">
        <div>
          
          <div class="nav-item col-3">
           
            <UserDashboard :panel_user_id="panel_user_id" :panel_user_role="panel_user_role" :panel_user_name="panel_user_name"/>




          </div>


        </div>
      </div>
    </div>
  </div>
</template>

<script>

import UserDashboard from "./AppHeader/UserDashboard.vue";


export default {
  name: "AppHeaderComponent",
  components:{"UserDashboard":UserDashboard},

  props: {
    type: String,
    username: String,
    alive_duration_init: Number,
  },
  data() {
    return {
      sidebarVisible: true,
      panel_store_password: "",
      panel_store_password_confirmation: "",
      panel_store_name:"",
      panel_store_type: 1,
      panel_user_store_result: "",
      panel_user_name: localStorage.getItem("panel_user_name"),
      panel_user_role: localStorage.getItem("panel_user_role"),
      panel_user_id: localStorage.getItem("panel_user_id"),
      alive_duration: this.$props.alive_duration_init,
      alive_duration_editing: false,
    };
  },
  directives: {},
  watch: {
    alive_duration_init: function (newVal) {
      // watch it
      this.alive_duration = newVal;
    },
  },
  methods: {
    
    onSidebarToggle() {
      // This function will be called whenever the sidebar is toggled

      this.sidebarVisible = !this.sidebarVisible;
      var main_element = document.getElementById("main")
      main_element.style.marginLeft = this.sidebarVisible == true ? "400px" : "0px"

    },
    
   
   
  },

  mounted() { },
};
</script>
<style>
.list-group-item {
  padding: 0 !important;
}

.fixed-sidebar {
  width: 400px;
}

.ms-400 {
  
  margin-left: 400px;
}

.sidebar-400>div {
  width: 400px !important;
  /* background-color: darkslategray!important; */
  
}
.b-sidebar-body{
  background-color: #454545;
}
.list-group-item{
  background-color: transparent;
}
.selected *{
  background-color: cadetblue!important;
  color: white!important;
}
.selected .fa-key{
  color: #ffbf00 !important;
}
.selected .fa-user-secret{
  color: #ff0000 !important;
}
</style>