<template>
    <div class="search-wrapper" ref="searchWrapper">
        <div class="input-holder" ref="inputHolder">
            <input type="text" v-model="search_word" @input="$props.search(search_word)" class="form-control mt-2" placeholder="Search ..." />
            <!-- <button class="fa fa-search" onclick="searchToggle(this, event);"><span></span></button> -->
        </div>
        <span class="close" onclick="searchToggle(this, event);"></span>
    </div>
</template>

<script>
// import apiClient from '@/helpers/apiClient';

export default {
    name:"SearchComponent",
    props: { search: Function },
    data() {
        return {
            search_word: "",
        }
    },
   
}
</script>

<style>

</style>