<template>
  <div>
    <div class="main container">

      <Header :agent_type="agent_type" :agent_description="agent_description" type="Command" :set-input-type="setInputType"
        :alive_time="get_human_readable_alive_elapsed_time(agent_time, srv_current_time)" :agent_name="agent_name"
        :agent_id="agent_id" :alive_duration_init="parseInt(alive_duration)" :search="search" />
      <hr />


      <div class="m-auto row w-75 mb-3 cmd-input" v-if="inputType == 0">
        <div class="col-1">
          <b-button v-on:click="addCommand()" class="btn btn-primary text-white mx-auto form-input">
            <i class="fa fa-terminal" aria-hidden="true"> </i>
          </b-button>
        </div>


        <!-- TODO:add history off commands -->
        <div class="col-10">
          <input type="text" 
            v-model="cmdbody" 
            :ref="`inputcmd${agent_id}`"
            placeholder="Enter The Command Body"
            v-on:keyup.enter="addCommand()" name="body"
            class="form-input w-100 bg-dark text-white rounded-3 ps-3 py-1" 
            @input="onInput"
            @focus="showSuggestions = true"
            @blur="hideSuggestions"
            autocomplete="on"
            list="suggestions"
            />
            <datalist id="suggestions">
              <option v-for="(suggestion,index) in suggestions" :key="index" :value="suggestion" > </option> 
              
            </datalist>
            

        </div>


        <div class="mt-3">


          <UsefulCmds :type="inputType.toString()" :retypeCommand="retypeCommand" />




        </div>



      </div>
      <div class="m-auto row w-75 mb-3 cmd-input" v-if="inputType == 1">
        <div :class="'col-1'">
          <b-button v-on:click="addDownload()" class="btn btn-primary text-white mx-auto"> <i
              class="fa fa-download"></i></b-button>
        </div>


        <div class="col-11">
          <input type="text" v-model="dlbody" :ref="`inputdl${agent_id}`"
            placeholder="Enter The Download Body for ex: C:\a.txt" v-on:keyup.esc="cancelDownload()"
            v-on:keyup.enter="addDownload()" name="body" class="w-100 bg-dark text-white rounded-3 ps-3 py-1" />
        </div>

        <div class="mt-3">


          <UsefulCmds :type="inputType.toString()" :retypeCommand="retypeDownload" />




        </div>



      </div>
      <div class="m-auto row w-75 mb-3 cmd-input" v-if="inputType == 2">
        <div :class="'col-1'">
          <b-button v-on:click="addUpload()" class="btn btn-primary text-white mx-auto"> <i
              class="fa fa-upload"></i></b-button>
        </div>


        <div class="col-11">
          <input type="file" v-on:change="uploadFile()" ref="body" name="body" class="w-25 " />
          <input type="text" :ref="`inputup${agent_id}`" required
            placeholder="Enter The Upload Path in Destination for example: C:\ProgramData\myFolder" v-model="uppath"
            class="w-75 bg-dark text-white rounded-3 ps-3 py-1" />
        </div>

        <div class="mt-3">


          <UsefulCmds :type="inputType.toString()" :retypeCommand="retypeUpload" />




        </div>



      </div>
      <div v-if="commands.length > 0">
        <b-table :id="`my-cmdtable${agent_id}`" class="table-styles rounded-3" :items="itemscmd" striped dark small>
          
          <template #cell(result_time)="data">
            <span v-if="data.value" class="badge bg-secondary">
              {{data.value}}
            </span>
          </template>
          <template #cell(command_time)="data">
            <span v-if="data.value" class="badge bg-primary">
              {{data.value}}
            </span>
          </template>

          <template #cell(result)="data">
            <div v-if="data.value.type == 0">
              <div v-if="data.value.result">
                <b-button variant="success" v-b-modal="'modalcmd-' + data.value.index"><i
                    class="fa fa-eye"></i></b-button>

                <b-modal :id="`modalcmd-${data.value.index}`" :title="'Result Of:\n' + data.value.body">
                  <div class="">
                    <button class="btn-secondary" v-on:click="copy(data.value.index)">copy</button>
                  </div>
                  <div>
                    <div disabled autofocus rows="30" cols="40" v-html="data.value.result"
                      style="color:black;background-color:#cbe4e6;white-space: pre-line" class="my-4"
                      :ref="`messagecmd${data.value.index}`">


                    </div>

                  </div>
                </b-modal>
              </div>
              <div v-else>
                <b-button variant="danger" disabled><i class="fa fa-ban"></i></b-button>
              </div>
            </div>
            <div v-else>
              <div v-if="data.value.result_time">
                <b-button variant="success" @click="download_file(data.value.item_path, data.value.fname)">
                  <i class="fa fa-download text-light"></i>
                </b-button>


              </div>
              <div v-else>
                <b-button variant="danger" disabled><i class="fa fa-ban"></i></b-button>
              </div>
            </div>
          </template>
          <template #cell(error_message)="data">
            <div v-if="data.value.error">
              <b-button variant="warning" v-b-modal="'modal-error' + data.value.index"><i
                  class="fa fa-exclamation-triangle "></i></b-button>

              <b-modal :id="`modal-error${data.value.index}`">
                <div class="">
                  <button class="btn btn-secondary" v-on:click="copy(data.value.index)">copy</button>
                </div>
                <div>
                  <textarea disabled autofocus rows="30" cols="40" :value="data.value.error"
                    style="color:black;background-color:#cbe4e6;white-space: pre-line" class="my-4"
                    :ref="`message-error${data.value.index}`">


                </textarea>
                </div>
              </b-modal>
            </div>
            <div v-else>
              <b-button variant="secondary" disabled><i class="fa fa-exclamation-triangle "></i></b-button>
            </div>
          </template>
          <template #cell(retype)="data">
            <div>
              <button class="btn btn-info text-light"
                v-on:click="data.value.type == 0 ? retypeCommand(data.value.content) : data.value.type == 1 ? retypeDownload(data.value.content) : retypeUpload(data.value.content)"><i
                  class="fa-solid fa-paste"></i></button>
            </div>
          </template>
          <template #cell(delivered_to_agent)="data">
            <div>

              <i v-if="data.value == '1'" class="fa fa-check text-success"></i>
              <i v-else class="fa fa-times text-danger"></i>
            </div>
          </template>
          <template #cell(delete)="data">
            <div>
              <button class="btn btn-danger text-light" v-on:click="deleteCommand(data.value)"><i
                  class="fa fa-trash"></i></button>
            </div>
          </template>
          <template #cell(type)="data">
            <div>
              <div class="btn btn-dark text-light" v-if="data.value == 0">
                <i class="fa fa-terminal"></i>
              </div>
              <div class="btn btn-primary text-light" v-if="data.value == 1">
                <i class="fa fa-download"></i>
              </div>
              <div class="btn btn-info text-light" v-if="data.value == 2">
                <i class="fa fa-upload"></i>
              </div>
            </div>
          </template>
          <template #cell(body)="data">
            <div :title="data.value" style="width:150px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
              v-html="data.value">

            </div>
          </template>
          <template #cell(path)="data">
            <div :title="data.value" style="width:150px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
              v-html="data.value">

            </div>
          </template>
          <template #cell(target_path)="data">
            <div :title="data.value" style="width:150px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
              v-html="data.value">

            </div>
          </template>
          <template #cell(file_name)="data">
            <div :title="data.value" style="width:150px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;"
              v-html="data.value">

            </div>
          </template>
          <template #cell(waiting)="data">
            <div v-if="data.value.show">
              <input type="checkbox"
                title="enable or disable in Dns Agent waiting for the process of file spliting and decoding in server!"
                :ref="`waitinginput${data.value.id}`" class="btn btn-info text-light"
                :checked="data.value.currentValue == 1 ? true : false" v-on:change="changeWaiting(data.value.id)" />
            </div>
          </template>

        </b-table>
        <b-row align-h="center" align-content="center" v-if="!search_word">
          <b-col cols="auto">
            <b-pagination v-model="currentPage" :total-rows="total_commands_number" :per-page="perPage"
              class="text-center m-auto"></b-pagination>
          </b-col>
        </b-row>

      </div>



      <div class="alert alert-danger mt-5" v-else>
        No Commands Available For This User
      </div>
    </div>
    <br />
    <br />
    <br />
    <br />
  </div>
</template>

<script>
import Header from "./Layout/Header.vue";
import apiClient from "@/helpers/apiClient";
import UsefulCmds from "./Tools/UsefulCmds.vue";
import { get_current_time, get_human_readable_alive_elapsed_time, hideProgress, perPage, showConfirmationDialog, showProgress, showToastErrorByResponse, showToastSuccessByResponse } from "@/helpers/helpers";
export default {
  name: "CommandsComponent",
  components: { 'Header': Header, 'UsefulCmds': UsefulCmds},

  props: { uid: Number },
  data() {
    return {

      currentPage: 1,
      perPage: perPage,
      agents: [],
      inputType: 0,
      query: '',
      suggestions:[],
      showSuggestions: false,
      running: false,
      upload_files: null,
      command_histories: ["ipconfig"],
      // user_id: this.$route.params.uid,
      agent_id: this.$props.uid ? this.$props.uid : this.$route.params.uid,
      agent_name: "",
      agent_time: "",
      new_useful_cmd: "",
      search_word: "",
      agent_type: "",
      usefulCmds: [],
      itemscmd: [],
      total_commands_number: 0,
      srv_current_time: "",
      alive_duration: 2,
      commands: [],
      addingCommand: false,
      cmdbody: "",
      dlbody: "",
      uppath: "",
      result: "",
      username: "",
      command_time: "",
      result_time: "",
    };
  },
  watch: {
    currentPage() {
      this.get_commands_by_agentid_from_backend(this.agent_id, this.currentPage)
    }
  },
  computed:{
    filteredSuggestions() {
        return this.suggestions.filter((suggestion) =>
          suggestion.toLowerCase().includes(this.query.toLowerCase())
        );
      },
  },
  methods: {
    numFormat(key) {
      return Number(key).toLocaleString();
    },
    onInput() {
        
        if(this.cmdbody.length>3){
          apiClient.get(`/api/command/history/index?s=${this.cmdbody}`).then((res)=>this.suggestions=res.data.command_histories)
        }
      },
      selectSuggestion(suggestion) {
        this.query = suggestion;
       
        
        this.showSuggestions = false;
        if (this.inputType==0) {
          this.retypeCommand(suggestion)
        }
        if (this.inputType==1) {
          this.retypeDownload(suggestion)
        }
        if (this.inputType==2) {
          this.retypeUpload(suggestion)
        }
      },
      hideSuggestions() {
        // Delay hiding to allow selection
        setTimeout(() => {
          this.showSuggestions = false;
        }, 1000);
      },
  
    numFormatWithDollar(key) {
      return key ? '$' + Number(key).toLocaleString() : '-';
    },

    localize(value, str) {
      if (value != 1)
        str += 's';

      return `${value} ${str} ago`
    },

    cancelCommand() {
      this.addingCommand = false;
    },
   
    retypeCommand(cmd) {
      var input = this.$refs[`inputcmd${this.agent_id}`]
      this.cmdbody = cmd
      input.value = cmd
    },

    retypeDownload(cmd) {
      var input = this.$refs[`inputdl${this.agent_id}`]
      this.dlbody = cmd
      input.value = cmd
    },
    retypeUpload(cmd) {
      var input = this.$refs[`inputup${this.agent_id}`]
      this.uppath = cmd
      input.value = cmd
    },

    deleteCommand(command_id) {

      showConfirmationDialog('Delete this Command', () => {
        apiClient.post(
    
          `/api/command/delete/${command_id}`)
          .then((res) => {

            this.commands = this.commands.filter((command) => { return command.id != command_id })
            this.addCommandsToTableArray();
            showToastSuccessByResponse(res, this)
          }).catch((res) => {
            showToastErrorByResponse(res, this)
          });
      }
        , this)

      // location.reload()
    },


    copy(index) {

      var elem_to_copy_from = this.$refs[`messagecmd${index}`]

      if (document.selection) {
        var range = document.body.createTextRange();
        range.moveToElementText(elem_to_copy_from);
        range.select().createTextRange();
        document.execCommand("copy");
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNode(elem_to_copy_from);
        window.getSelection().addRange(range);
        document.execCommand("copy");
      }
    },
    addCommand() {
      if (!this.cmdbody) {
        showToastErrorByResponse("Please Enter Body First!", this)
        return
      }
      var data_to_store = {
        body: this.cmdbody,
        result: null,
        agent_id: this.agent_id ? this.agent_id : this.username,
        result_time: null,
        command_time: get_current_time(),
      };

      this.commands.push(data_to_store);
      this.addCommandsToTableArray()
      showProgress()
      apiClient.post(
        `/api/command/store`,
        data_to_store
      ).then((res) => {
        hideProgress()
        this.cmdbody = "";
        this.result = "";
        this.username = "";
        this.command_time = "";
        this.result_time = "";
        // location.reload();

        showToastSuccessByResponse(res, this)
      }).catch((res) => {
        showToastErrorByResponse(res, this)
      });


      // }

      // this.addingCommand = !this.addingCommand;
    },

    addDownload() {
	
      if (!this.dlbody) {
        showToastErrorByResponse("Please Enter Body First!", this)
        return
      }



      var data_to_store = {
        body: this.dlbody,
        result: "",
        agent_id: this.agent_id ? this.agent_id : this.username,
        result_time: "",
        path: "",
        // dl_time: this.get_current_time(),//TODO:handle in backend
        file_name: this.dlbody.split("/")[this.dlbody.split("/").length - 1]
      };
      this.commands.push(data_to_store);
      this.addCommandsToTableArray()
      showProgress()
      apiClient.post(
        `/api/download/store`,
        data_to_store
      ).then((res) => {
        hideProgress()
        this.dlbody = "";
        this.result = "";
        this.username = "";
        this.download_time = "";
        this.result_time = "";
        showToastSuccessByResponse(res, this)
        // location.reload();
      }).catch((res) => {

        showToastErrorByResponse(res, this)
      });



    },
    uploadFile() {
      this.upload_files = this.$refs.body.files[0];
    },
    addUpload() {
	if (!this.uppath) {
        showToastErrorByResponse("Please Enter Path First!", this)
        return
      }




      const formData = new FormData();
      formData.append('file_content', this.upload_files);
      formData.append('file_name', this.upload_files.name);
      formData.append('agent_id', this.agent_id ? this.agent_id : this.username);
      // formData.append('up_time', this.get_current_time());//TODO:handle in backend
      formData.append('path', this.uppath ? this.uppath : "./");


      const headers = { 'Content-Type': 'multipart/form-data' };
      showProgress()
      apiClient.post(`/api/upload/store`, formData, { headers }).then((res) => {
        // res.data.files; // binary representation of the file
        // res.status; // http${this.backend_port==443?"s":""} status
        hideProgress()
        // location.reload()
        showToastSuccessByResponse(res, this)
      }).catch((res) => {
        showToastErrorByResponse(res, this)
      })


      // }

      // this.addingUpload = !this.addingUpload;
    },

    new_useful_cmd_store() {
      var new_useful_cmd_ = this.new_useful_cmd

      var data_to_store = { cmd: new_useful_cmd_, type: 0 }
      showProgress()
      apiClient.post("/api/usefulcmd/store", data_to_store).then((res) => {
        showToastSuccessByResponse(res, this)
        hideProgress()
        this.usefulCmds.push(data_to_store)
        this.new_useful_cmd = ""
      }).catch((res) => {
        showToastErrorByResponse(res, this)
      })
    },


    get_commands_by_agentid_from_backend(agent_id, page) {
      showProgress()
      apiClient
        .get(
          `/api/command/index?uid=${agent_id}&page=${page}`
        )
        .then((result) => {
          hideProgress()
          this.command_histories=result.data.command_histories
          this.suggestions = result.data.command_histories
          this.srv_current_time = result.data.current_time

          if (result.data.agent) {
            var received_agent = result.data.agent
            if (received_agent.id == this.agent_id) {
              this.agent_name = received_agent.name
              this.agent_type = received_agent.agent_type
              this.agent_description=received_agent.description
              this.agent_time = received_agent.alive_time
              this.alive_duration = received_agent.alive_duration
            }

          }
          if (result.data.commands != undefined) {
            this.commands = []
            this.commands = result.data.commands;
            this.total_commands_number = result.data.tot_commands_number

            this.addCommandsToTableArray();

          }
          else {
            this.commands = []
          }
          // this.running = true;

        }).catch((res) => {
          showToastErrorByResponse(res, this)

        });



    },
    showCommandSuggestions(input_value) {
      this.command_histories = this.command_histories.filter((cmd) => cmd.includes(input_value))
    },
    search(word) {
      this.search_word = word
      if (word.length == 0) {
        this.get_commands_by_agentid_from_backend(this.agent_id, this.currentPage)
        return;
      }
      apiClient
        .get(
          `/api/command/index?uid=${this.agent_id}&search=${word}`
        )
        .then((result) => {


          this.srv_current_time = result.data.current_time


          if (result.data.commands != undefined) {
            this.commands = []
            var search_result_commands = [];

            result.data.commands.forEach((command) => {
              if (command.body) {
                command.body = command.body.replace(word, `<span style="background-color:red;">${word}</span>`)

              }
              if (command.result) {
                command.result = command.result.replace(word, `<span style="background-color:red;">${word}</span>`)

              }
              search_result_commands.push(command)
            })

            this.commands = search_result_commands
            this.total_commands_number = result.data.tot_commands_number

            this.addCommandsToTableArray();

          }
          else {
            this.commands = []
          }
          // this.running = true;

        }).catch((res) => {
          showToastErrorByResponse(res, this)

        });

    },


    addCommandsToTableArray() {
      this.itemscmd = []
      this.commands.forEach((command, index) => {
        this.itemscmd.push({
          number: index + 1,
          type: command.type,
          body: command.body,
          delivered_to_agent: command.delivered,
          result: command.type == 0 ? { result: command.result, index: index, body: command.body, type: 0 } : command.type == 1 ? { item_path: command.path ? btoa(command.path) : "", fname: (command.path.split('/')[command.path.split('/').length - 1]), result_time: command.result_time, type: 1 } : "",
          error_message: { error: command.err_msg, index: index },

          ...(!this.agent_id && { username: command.username }),
          command_time: command.command_time ? get_human_readable_alive_elapsed_time(command.command_time, this.srv_current_time) : "",
          result_time: command.result_time ? get_human_readable_alive_elapsed_time(command.result_time, this.srv_current_time) : "",
          retype: { content: command.type == 0 ? command.body : command.type == 1 ? command.body : command.target_path, type: command.type },

          file_name: command.file_name,
          target_path: command.target_path,
          path: command.path,
          waiting: { currentValue: command.waiting, id: command.id, show: command.type == 2 },




          delete: command.id
        });
      });

      // this.items.push({ body: "ep56j[hjew[j]]", username: "ep56j[hjew[j]]" });
    },
    setInputType(type) {
      this.inputType = type
    },
    download_file(item_path, fname) {
      showProgress()
      apiClient
        .get(`/api/dl/${item_path}`, {
          responseType: 'blob'
        }).then((res) => {
          
          const url = URL.createObjectURL(res.data)
          const a = document.createElement('a')
          a.href = url
          a.download = fname
          a.style.display = 'none'
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)
          hideProgress()
        }).catch((res) => {

          showToastErrorByResponse(res, this)
        });
    },

  },

  mounted() {
    this.get_human_readable_alive_elapsed_time = get_human_readable_alive_elapsed_time
    this.commands = []
    if (this.agent_id) {
      this.get_commands_by_agentid_from_backend(this.agent_id, this.currentPage);
    }
    // this.get_agents_from_backend();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.autocomplete {
    position: relative;
    width: 200px;
  }
  
  ul.suggestions {
    position: absolute;
    width: 40%;
    border: 1px solid #ccc;
    max-height: 150px;
    overflow-y: auto;
    background: white;
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  
  .suggestions li {
    padding: 8px;
    display: block!important;
    cursor: pointer;
    z-index: 1000;
    pointer-events: auto; /* Ensures clicks are allowed */
  }
  
  .suggestions li:hover {
    background-color: #eb9f9f;
  }
  .suggestions{
    z-index: 100;
  }
</style>
