import Vue from 'vue'
import App from './App.vue'
import VueConfirmDialog from 'vue-confirm-dialog'


Vue.config.productionTip = false
import router from "./router";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueRouter from 'vue-router';
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import * as helper from './helpers/helpers'


/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';
/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
process.env.TZ ="America/New_York";
Vue.use(VueRouter)
Vue.use(helper)

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)
/* add icons to the library */

library.add(faUserSecret,faTrash)
Vue.component(FontAwesomeIcon)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ToastPlugin)
// function timeDiff(date1, date2) {
//   if (!(date1 instanceof Date && date2 instanceof Date))
//     throw new RangeError('Invalid date arguments');

//   const timeIntervals = [31536000, 2628000, 604800, 86400, 3600, 60, 1];
//   const intervalNames = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second'];

//   const diff = Math.abs(date2.getTime() - date1.getTime()) / 1000;
//   const index = timeIntervals.findIndex(i => (diff / i) >= 1);
//   const n = Math.floor(diff / timeIntervals[index]);
//   const interval = intervalNames[index];

//   return [n, interval];
// }

// function localize(value, str) {
//   if (value != 1)
//     str += 's';

//   return `${value} ${str} ago  `
// }
// function get_human_readable_alive_elapsed_time(alive_time) {
//   const olderDate = new Date(alive_time);
//   const currentDate = new window.Date();
//   return this.localize(...this.timeDiff(currentDate, olderDate));
// }
// Vue.use(router)

new Vue({
  router:router,
  render: h => h(App),
}).$mount('#app')
